<template>
  <div class="container-fluid p-4">
    <h1>Monitoring</h1>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between">
          <!-- Main table element -->
          <b-table
            :busy="busy"
            :items="getMonitor"
            :fields="monitorTableFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            small
            show-empty
            striped
            stacked="md"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(id)="row">
              <div class="d-flex justify-content-start">
                <DeviceStatus :status="row.item.status"></DeviceStatus>
                {{ row.item.id }}
              </div>
            </template>
            <template #cell(timestamp)="row">
              <time-since :date="row.item.timestamp"></time-since>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DeviceStatus from "@/components/FloorplanDevices/DeviceStatus/DeviceMonitorStatus";

export default {
  components: {
    DeviceStatus
  },
  data: function () {
    return {
      pageTitle: "Monitoring",
      availableFilters: [],
      monitorTableFields: [
        { key: "id", label: "Services", sortable: true, sortDirection: "asc" },
        { key: "URL", label: "URL", sortable: true, sortDirection: "asc" },
        {
          key: "timestamp",
          label: "Last Checked",
          sortable: true,
          sortDirection: "asc"
        }
      ],
      busy: false,
      servicesStatusText: "Services",
      sortBy: "id",
      sortDesc: true,
      sortDirection: "asc",
      showFilters: false,
      selectedFilters: null,
      currentFiltersText: ""
    };
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL",
      getWSServerURL: "session/getWSServerURL",
      getWSServerKey: "session/getWSServerKey",
      getMonitor: "monitor/getMonitor",
      getLog: "monitor/getLog"
    })
  },
  mounted() {
    this.getMonitorList();
    this.timer();
  },
  unmounted() {},
  methods: {
    timer() {
      let that = this;
      let intervalId = window.setInterval(function () {
        //console.log("polling...", intervalId);
        that.getMonitorList();
      }, 30000);
    },
    getMonitorList: function () {
      let $this = this;
      $this.busy = true;
      //let API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      let API_URL =
        $this.getAPIServerURL + "/api/monitorservice/status/ipmonitor/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          if (result != undefined) {
            for (let monitor_url in result) {
              let monitor_data = result[monitor_url];
              for (let e in monitor_data) {
                let monitor = monitor_data[e];
                for (let i in monitor) {
                  let monitorservice_id = i;
                  let monitors_url = monitor[i];
                  for (let URL in monitors_url) {
                    let monitor_list = monitors_url[URL];

                    let payload = {
                      ...monitor_list,
                      id: monitorservice_id,
                      URL: URL
                    };

                    let url = "http://127.0.0.1:8001";
                    let urls = url.substr(0, 17);

                    let dev_id = "DEV_CAM";
                    let dev = dev_id.substr(0, 3);

                    if (URL.includes(urls)) {
                      $this.$store.dispatch("monitor/addLog", payload);
                    } else {
                      if (monitorservice_id.includes(dev)) {
                        //do nothing
                      } else {
                        let time = moment(payload.timestamp).format("HH:mm:ss");
                        console.log("payload", time);
                        $this.$store.dispatch("monitor/addMonitor", payload);
                      }
                    }
                  }
                }
              }
              $this.busy = false;
            }
            $this.fetchFilters();
          }
        })
        .catch((err) => {
          console.log(err);
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          this.$store.dispatch("session/logoutSession");
        });
    },
    selectServicesName: function (monitorservice_id) {
      let $this = this;
      let service_id = monitorservice_id.id;
      let result = $this.$store.getters["monitor/getMonitor"];
      $this.$store.dispatch("monitor/resetStore");
      for (let e in result) {
        if (service_id == result[e].id) {
          let monitor_data = result[e];
          let data = {
            ...monitor_data
          };
          $this.$store.dispatch("monitor/addMonitor", data);
        }
      }
    },

    fetchFilters: function () {
      let $this = this;
      let serviceNameList = [];
      let statusList = [];
      $this.availableFilters = [
        {
          label: "Services",
          key: "id",
          type: "select",
          choices: serviceNameList,
          obj_key: "id",
          obj_value: "name"
        },
        {
          label: "Status",
          key: "status",
          type: "select",
          choices: statusList,
          obj_key: "id",
          obj_value: "name"
        }
      ];
      $this.selectedFilters = {
        created_at: null,
        created__lte: null
      };
    }
  }
};
</script>
